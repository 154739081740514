import * as React from 'react';
import { useState, useEffect } from 'react';

export default function ImageWithBlurEffect(props) {
    let currentBlur = props.initialBlurlevel;

    const [blurLevel, setImgBlurLevel] = useState(props.initialBlurlevel);

    useEffect(() => {
        const i = setInterval(() => {
            setImgBlurLevel(currentBlur);

            if (currentBlur > 0) {
                currentBlur = currentBlur - 0.1;
            } else {
                clearInterval(i);
            }
        })
    }, [])

    return <img style={{filter: `blur(${blurLevel}px)`}} src={props.imgFilePath} />;
}
