import * as React from 'react';
import { useState, useEffect } from 'react';
import {makeShuffledIntegerArrayWithSameLength, getRandomColor, stringToListOfChars, stringIsLineChangeOrSpace} from "./Functions";

export default function FlyingTextArea(props) {
    const [chars, setChars] = useState(props.txt);
    const [listsOfChars, setListsOfChars] = useState([]);
    const [listsOfCharsWasUpdated, setListOfWordsWasUpdated] = useState(false);
    const [listsOfCharsAndStyles, setListsOfCharsAndStyles] = useState([]);
    const [recolorIsDone, setRecolorIsDone] = useState(false);

    if (props.txt !== chars) {
        setChars(props.txt);
    }

    useEffect( () => {
        setListsOfChars(stringToListOfChars(chars));
    }, [chars]);

    const makeCharBgAndColorBlack = () => {
        let newListsOfCharsAndStyles = [];
        let wordNo = 0;

        if (listsOfChars.length > 0) {
            listsOfChars.forEach((word) => {
                newListsOfCharsAndStyles.push({
                    bgColor: props.initialColor,
                    color: props.initialColor,
                    key: wordNo,
                    word,
                });

                wordNo += 1;
            });
        }

        setListsOfCharsAndStyles(newListsOfCharsAndStyles);
    };

    useEffect(() => {
        makeCharBgAndColorBlack();
        setListOfWordsWasUpdated(!listsOfCharsWasUpdated);
    }, [listsOfChars]);

    useEffect(() => {
        makeCurrentWordsAppear();
    }, [listsOfCharsWasUpdated]);

    const setListsOfCharsAndStylesNodeColors = (bgColor, color, nodeNo) => {
        let newListsOfCharsAndStyles = [...listsOfCharsAndStyles];
        if (newListsOfCharsAndStyles[nodeNo].word !== ' ') {
            newListsOfCharsAndStyles[nodeNo].bgColor = bgColor
            newListsOfCharsAndStyles[nodeNo].color = color;
        }
        setListsOfCharsAndStyles(newListsOfCharsAndStyles);
    }

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    async function makeCurrentWordsAppear() {
        const arr = makeShuffledIntegerArrayWithSameLength(listsOfCharsAndStyles);

        for (let i = 0; i < arr.length; i++) {
            setListsOfCharsAndStylesNodeColors(getRandomColor(), 'white', arr[i]);
            if (!stringIsLineChangeOrSpace(listsOfCharsAndStyles.at(arr[i]).word)) {
                await sleep(props.sleep);
            }
        }

        await recolor();
    }

    async function recolor() {
        if (listsOfCharsAndStyles.length > 0) {
            const arr = makeShuffledIntegerArrayWithSameLength(listsOfCharsAndStyles);

            for (let i = 0; i < arr.length; i++) {
                setListsOfCharsAndStylesNodeColors(props.bgColor, props.color, arr[i]);
                if (!stringIsLineChangeOrSpace(listsOfCharsAndStyles.at(arr[i]).word)) {
                    await sleep(props.sleep);
                }
            }

            await setRecolorIsDone(true);
        }
    }

    const createColoredSpanForWord = (bgColor, color, key, word) => {
        let divStyle = {
            color,
            backgroundColor: bgColor,
            fontSize: props.fontSize,
            fontFamily: 'Garamond',
        };

        return (word === '\n')
            ? <br />
            : <span style={divStyle} key={key}>{word}</span>;
    };

    return (
        <>
            {listsOfCharsAndStyles.length !== 0 && listsOfCharsAndStyles.map((words) => (
                props.link && recolorIsDone
                    ?
                <a href={props.link} style={{'text-decoration': 'none'}}>
                    {createColoredSpanForWord(words.bgColor, words.color, words.key, words.word)}
                </a>
                    :
                    createColoredSpanForWord(words.bgColor, words.color, words.key, words.word)
            ))}
        </>
    );
}
