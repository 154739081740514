import * as React from 'react';
import FlyingTextArea from "./FlyingTextArea";
import {getRandomColor, getRandomIntFromRange} from "./Functions";
import ImageWithBlurEffect from "./ImageWithBlurEffect";

const colorChoices = ['black', 'white', getRandomColor()];
let bgColor = colorChoices.at(getRandomIntFromRange(0, 3));

export default function App() {
    const bodyDivStyle = {
        backgroundColor: bgColor,
        verticalAlign: 'middle',
        textAlign: 'center',
    };

    const topBarStyle = {
        position: 'sticky',
        backgroundColor: 'black',
        verticalAlign: 'middle',
        textAlign: 'center',
        top: '20px'
    }

    const footerStyle = {
        verticalAlign: 'middle',
        textAlign: 'center',
    };

    return (
        <>
            <div style={topBarStyle}>
                <ImageWithBlurEffect imgFilePath={require('./flying_logo_colorking_png_mv_pienennetty.png')} initialBlurlevel={50}></ImageWithBlurEffect>
            </div>
            <div style={bodyDivStyle}>
                <br />
                <br />
                <FlyingTextArea fontSize={17} sleep={60} key={1} bgColor={"black"} color={"white"} initialColor={bgColor} txt={"MANSE ROCK FROM TAMPERE"}></FlyingTextArea>
                <br />
                <FlyingTextArea fontSize={17} sleep={80} key={2} bgColor={"black"} color={"white"} initialColor={bgColor} txt={"WELCOME FRIEND"}></FlyingTextArea>
                <br />
                <br />
                <ImageWithBlurEffect imgFilePath={require('./flying_colorking_kansi_pienennetty.png')} initialBlurlevel={75}></ImageWithBlurEffect>
                <br />
                <FlyingTextArea fontSize={17} sleep={60} key={3} bgColor={"black"} color={"white"} initialColor={bgColor} txt={"NEW ALBUM COLORKING 7.6.2024!"}></FlyingTextArea>
                <br />
                <br />
                <FlyingTextArea fontSize={17} sleep={90} key={4} bgColor={"black"} color={"white"} initialColor={bgColor} txt={"MUSIC AVAILABLE ON "}></FlyingTextArea>
                <FlyingTextArea fontSize={17} sleep={250} key={5} bgColor={"limegreen"} color={"black"} link={"https://open.spotify.com/artist/24AuI9I1cKz9iwkGA4omX1?si=GNoRI3XDSaq237-NSo4_jA"} initialColor={bgColor} txt={"SPOTIFY"}></FlyingTextArea>
                <FlyingTextArea fontSize={17} sleep={90} key={6} bgColor={"black"} color={"white"} initialColor={bgColor} txt={" AND "}></FlyingTextArea>
                <FlyingTextArea fontSize={17} sleep={270} key={7} bgColor={"cyan"} color={"gray"} link={"http://flying.bandcamp.com/"} initialColor={bgColor} txt={"BANDCAMP"}></FlyingTextArea>
                <br />
                <br />
                <br />
                <FlyingTextArea fontSize={17} sleep={100} key={8} bgColor={"black"} color={"white"} initialColor={bgColor} txt={"NEXT GIG 28.6.2025 AT "}></FlyingTextArea>
                <FlyingTextArea fontSize={17} sleep={200} key={9} bgColor={"orange"} color={"cyan"} link={"http://www.lapsuudenkesa.com"} initialColor={bgColor} txt={"LAPSUUDEN KES�"}></FlyingTextArea>
                <br />
                <br />
                <br />
                <FlyingTextArea fontSize={17} sleep={100} key={10} bgColor={"black"} color={"white"} initialColor={bgColor} txt={"PLEASE VISIT OUR "}></FlyingTextArea>
                <FlyingTextArea fontSize={17} sleep={300} key={11} bgColor={"blue"} color={"white"} link={"http://www.facebook.com/weareffflying"} initialColor={bgColor} txt={"FACEBOOK"}></FlyingTextArea>
                <FlyingTextArea fontSize={17} sleep={100} key={12} bgColor={"black"} color={"white"} initialColor={bgColor} txt={" AND "}></FlyingTextArea>
                <FlyingTextArea fontSize={17} sleep={310} key={13} bgColor={"lightgray"} color={"black"} link={"http://www.instagram.com/weareffflying"} initialColor={bgColor} txt={"INSTAGRAM"}></FlyingTextArea>
                <br />
                <br />
            </div>
            <div style={footerStyle}>
                <FlyingTextArea fontSize={17} sleep={100} key={14} bgColor={"black"} color={"white"} initialColor={"black"} txt={"CONTACTS: info@weareflying.com"}></FlyingTextArea>
                <br />
            </div>
        </>
    );
}
