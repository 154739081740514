export const stringToListOfChars = (str) => {
    return str.split('');
}

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
export const makeShuffledIntegerArrayWithSameLength = array => {
    return shuffle(
        Array.apply(null, Array(array.length)).map(function (
            y,
            i
        ) {
            return i;
        })
    );
};

export const getRandomColor = () => {
    return `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`;
};

export const getRandomIntFromRange = (rangeBegin, rangeEnd) =>Math.random() * (rangeEnd - rangeBegin) + rangeBegin;

export const stringIsLineChangeOrSpace = str =>
    (str === '\n') || (str === ' ');
